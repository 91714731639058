.global-bar {
  width: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(1, 73, 131, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    linear-gradient(to bottom, #00529b, #00529b);
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.global-bar .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex: 1;
  padding: 0 24px;
  height: 60px;
  overflow: hidden;
}

.global-bar .nav .content-left {
  text-decoration: none;
  line-height: 1.2;
  display: flex;
  align-items: center;
}

.global-bar .nav .content-left .gov-logo {
  height: 40px;
  margin-right: 8px;
}

.global-bar .nav .content-left span:last-child {
  opacity: 0.5;
  margin-left: 4px;
}

.global-bar .nav .content-left span {
  color: #fff;
  font-size: 1.26rem;
}

.global-bar .nav .content-right {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.content-right > div {
  display: inline-block;
  position: relative;
  cursor: pointer;

  .copied-message {
    background: #fff;
    border-radius: 3px;
    padding: 4px 8px;
    position: absolute;
    top: 32px;
    left: 0;
    font-size: 14px;
  }

  > div {
    display: none;
    margin-top: 10px;
    width: 122px;
    padding: 0;
    height: 40px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: #f0f8ff;
  }

  > div.show {
    display: flex;
    pointer-events: auto;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 4px 0px;

    &:before {
      position: absolute;
      content: "";
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      top: -7px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 7px solid #f0f8ff;
    }

    > div {
      width: 33%;
      height: 100%;
    }

    .facebook,
    .twitter,
    .website {
      box-sizing: border-box;
      background-position: center;
      &:hover {
        filter: none;
      }
    }

    .facebook {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0'%3F%3E%3Csvg fill='%232f80ed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24px' height='24px'%3E%3Cpath d='M16.403,9H14V7c0-1.032,0.084-1.682,1.563-1.682h0.868c0.552,0,1-0.448,1-1V3.064c0-0.523-0.401-0.97-0.923-1.005 C15.904,2.018,15.299,1.999,14.693,2C11.98,2,10,3.657,10,6.699V9H8c-0.552,0-1,0.448-1,1v2c0,0.552,0.448,1,1,1l2-0.001V21 c0,0.552,0.448,1,1,1h2c0.552,0,1-0.448,1-1v-8.003l2.174-0.001c0.508,0,0.935-0.381,0.993-0.886l0.229-1.996 C17.465,9.521,17.001,9,16.403,9z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 21px; 
    -webkit-filter: grayscale(1);
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
    }

    .twitter {
      background-image: url("data:image/svg+xml,%3Csvg fill='%232f80ed' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32px' height='32px'%3E%3Cpath d='M 28 8.558594 C 27.117188 8.949219 26.167969 9.214844 25.171875 9.332031 C 26.1875 8.722656 26.96875 7.757813 27.335938 6.609375 C 26.386719 7.171875 25.332031 7.582031 24.210938 7.804688 C 23.3125 6.847656 22.03125 6.246094 20.617188 6.246094 C 17.898438 6.246094 15.691406 8.453125 15.691406 11.171875 C 15.691406 11.558594 15.734375 11.933594 15.820313 12.292969 C 11.726563 12.089844 8.097656 10.128906 5.671875 7.148438 C 5.246094 7.875 5.003906 8.722656 5.003906 9.625 C 5.003906 11.332031 5.871094 12.839844 7.195313 13.722656 C 6.386719 13.695313 5.628906 13.476563 4.964844 13.105469 C 4.964844 13.128906 4.964844 13.148438 4.964844 13.167969 C 4.964844 15.554688 6.660156 17.546875 8.914063 17.996094 C 8.5 18.109375 8.066406 18.171875 7.617188 18.171875 C 7.300781 18.171875 6.988281 18.140625 6.691406 18.082031 C 7.316406 20.039063 9.136719 21.460938 11.289063 21.503906 C 9.605469 22.824219 7.480469 23.609375 5.175781 23.609375 C 4.777344 23.609375 4.386719 23.585938 4 23.539063 C 6.179688 24.9375 8.765625 25.753906 11.546875 25.753906 C 20.605469 25.753906 25.558594 18.25 25.558594 11.742188 C 25.558594 11.53125 25.550781 11.316406 25.542969 11.105469 C 26.503906 10.410156 27.339844 9.542969 28 8.558594 Z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 25px; 
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      border-left: 1px solid #b2b6ba;
      border-right: 1px solid #b2b6ba;
    }
    .website {
    background-image: url("data:image/svg+xml,%3Csvg fill='%232f80ed' version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 162.656 162.656' style='enable-background:new 0 0 162.656 162.656;' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M151.764,10.894c-14.522-14.522-38.152-14.525-52.676-0.008l0.003,0.003L76.112,33.872l10.607,10.605l22.983-22.988 l-0.002-0.002c8.678-8.663,22.785-8.658,31.457,0.014c8.673,8.672,8.672,22.786,0,31.461l-34.486,34.484 c-4.201,4.202-9.787,6.516-15.729,6.516c-5.942,0-11.529-2.314-15.73-6.516L64.605,98.052c7.035,7.035,16.389,10.91,26.338,10.91 c9.949,0,19.303-3.875,26.335-10.91l34.487-34.484C166.284,49.043,166.284,25.413,151.764,10.894z'/%3E%3Cpath d='M52.96,141.162L52.96,141.162c-8.675,8.67-22.788,8.668-31.461-0.005c-8.673-8.675-8.673-22.791-0.001-31.465L55.98,75.21 c8.675-8.674,22.789-8.674,31.462,0L98.05,64.604c-14.524-14.523-38.154-14.524-52.676,0L10.89,99.086 c-14.519,14.523-14.519,38.154,0.001,52.678c7.263,7.262,16.801,10.893,26.341,10.892c9.536,0,19.074-3.629,26.333-10.887 l0.002-0.001l22.984-22.99l-10.608-10.606L52.96,141.162z'/%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 18px; 
     -webkit-filter: grayscale(1);
      filter: grayscale(1);
      display: inline-block;
      text-indent: -999999px;
      height: 100%;
      width: 33%;
    }
  }

  a {
    opacity: 0.6;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 1;
    }

    span {
      color: #fff;
      font-size: 1rem;
      margin-right: 8px;
      @include respond(md) {
        display: none;
      }
    }

    img {
      width: 14px;
      filter: brightness(0) invert(1);
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.content-right > div,
.content-right > ul {
  margin-right: 12px;
}

.content-right .font-changer {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: 60px;
  display: inline-flex;
  padding: 0;

  > li:nth-child(1) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  > li {
    box-sizing: border-box;
    color: #fff;
    opacity: 0.6;
    font-size: 14px;
    padding: 0 6px;
    width: 33.43%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;
  }
}
