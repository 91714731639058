$col_space: 12px;

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  .col-1-2,
  .col-1-3,
  .col-1-4 {
    margin: 0 12px;
  }

  .col-1-2 {
    width: calc((100% - (#{$col_space} * 4)) / 2);
  }

  .col-1-3 {
    width: calc((100% - (#{$col_space} * 5)) / 3);
  }

  .col-1-4 {
    width: calc((100% - (#{$col_space} * 8)) / 4);
  }
}

.table-scroll {
  /*width:100%; */
  display: block;
  empty-cells: show;
  /* Decoration */

  thead {
    position: relative;
    display: block;
    width: 100%;
    overflow-y: scroll;
  }

  tbody {
    /* Position */
    display: block;
    position: relative;
    width: 100%;
    overflow-y: scroll;
    /* Decoration */
  }

  tr {
    width: 100%;
    display: flex;
  }

  td,
  th {
    flex-basis: 100%;
    flex-grow: 2;
    display: block;
    padding: 1rem;
    text-align: left;
  }

  /* Other options */

  &.small-first-col td:first-child,
  .table-scroll.small-first-col th:first-child {
    flex-basis: 20%;
    flex-grow: 1;
  }

  .body-half-screen {
    max-height: 280px;
  }

  tbody tr {
    border-bottom: none !important;
  }
}
