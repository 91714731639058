//-----------------------------------------------
//  content
//-----------------------------------------------

.content {
  position: relative;

  .content-left {
    width: 75%;
    margin: 0 auto;

    @include respond(md) {
      width: 100%;
    }
  }

  .table__block {
    padding-top: 25px;

    &:not(:first-child) {
      margin-top: 75px;
      @include respond(sm) {
        margin-top: 55px;
      }
    }
  }

  .table__parent {
    overflow-x: auto;
    border-radius: 12px;
  }

  table {
    th,
    th:first-of-type td,
    td:first-of-type {
      padding: 12px 20px;
    }

    th,
    td {
      border-left: 1px solid #f0f8ff !important;
    }
  }

  thead,
  tr,
  tr:first-of-type {
    border-bottom: 1px solid #f0f8ff !important;
  }

  th {
    font-weight: 400;
    color: rgba(40, 57, 71, 0.5);
  }

  table tbody tr td {
    padding: 12px 20px;
  }

  .location__link,
  .ph__link {
    display: inline-flex;
    align-items: center;
    transition: 0.3s;
    color: $color_primary_blue;
  }

  .ph__link-comma{
    @include respond(lg) {
      display: none;
    }
  }

  .ph__link:last-child {
    margin-left: 4px;
  }

  .location__icon-wrap {
    margin-left: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    background: $color_primary_blue;
    color: $color_primary_white;
    transition: 0.3s;
  }
}

.scrolling {
  margin-right: -16px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 65px;
    height: 100%;
    background: linear-gradient(90deg, rgba(240, 248, 255, 0) 0%, #f0f8ff 100%);
    pointer-events: none;
  }
}

//-----------------------------------------------
//  mun-data
//-----------------------------------------------

.mun-data {
  padding-bottom: 75px;
  @include respond(sm) {
    padding-bottom: 55px;
  }
}

#committee {
  padding-top: 25px;

  .table__wrapper .heading__secondary {
    font-weight: 400;
    font-size: 16px;
  }
}

table {
  th,
  th:first-of-type td,
  td:first-of-type {
    padding: 12px 20px !important;
  }

  th,
  td {
    border-left: 1px solid #f0f8ff;
  }
}
