.thanks {
 padding: 25px 0 75px;

  @include respond(sm) {
    padding: 25px 0 55px;
  }

  .heading__secondary {
    @include respond(sm) {
      margin-bottom: 24px;
    }
  }

  &__item-list {
    @include respond(xsm) {
      flex-direction: row;
    }
  }

  &__item:not(:last-child) {
    margin-right: 32px;
    @include respond(xsm) {
      margin-bottom: 24px;
    }
  }

  &__avatar {
    height: 37px;
    width: 37px;
    margin-right: 9px;
  }

  &__name,
  &__link {
    font-weight: 700;
  }
  &__link {
    margin-left: 4px;
    width: 16px;
    height: 16px;
    line-height: 0;
    color: $color_primary_blue;
    &:hover {
      color: $color_primary_blue_darkest;
    }
  }
  &__person-job {
    font-size: 13px;
  }
}
