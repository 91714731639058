.ReactModal__Overlay.ReactModal__Overlay--after-open {
  display: flex;
  align-items: center;
  padding: 32px 0;
  background: rgba(249, 204, 220, 0.9) !important;
  z-index: 99999999999999999 !important;
}

.ReactModal__Overlay .ReactModal__Content {
  margin: 0 auto;
  width: 600px;
  overflow: visible;
  @include respond(sm) {
    width: 100%;
  }
  &:focus {
    outline: none;
  }

  > div {
    border-radius: 12px;
    margin: 0 auto;
    padding: 32px;
    background: $color_primary_white;
    @include respond(sm) {
      padding: 32px 24px;
    }

    @include respond(xsm) {
      padding: 24px 16px;
    }
  }

  .heading__primary {
    margin-top: 16px;
    @include respond(sm) {
      font-size: 1rem;
    }
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

.modal__content {
  background: $color_primary_white;
}

.modal__content-desc-only {
  .modal__video-player-desc {
    height: auto;
    max-height: 280px;
  }
}

//-----------------------------------------------
//  modal video__player
//-----------------------------------------------

.modal__video-player {
  width: 540px;
  @include respond(sm) {
    width: 100%;
    margin-top: 0;

    > div {
      padding: 50px 16px;
      align-items: unset;
    }
  }

  &-desc {
    max-height: 190px;
    overflow-y: auto;
    font-size: 14px;
    padding-right: 16px;

    @include respond(xsm) {
      max-height: 110px;
    }
  }

  &::before {
    content: "";
    width: 200vw;
    height: 200vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background: #283947;
    opacity: 0.7;
    transform: translate(-50%, -50%);
  }
}

.modal__video-player .iframe__wrap-parent {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin: 0 auto 16px;
}

.modal__video-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//-----------------------------------------------
//  modal contact
//-----------------------------------------------

.modal__contact-wrapper {
  position: fixed;
  bottom: 92px;
  right: 16px;
  z-index: 3;

  @include respond(sm) {
    bottom: 80px;
    right: 50%;
    transform: translateX(50%);
  }
}

.modal__contact {
  .modal__content {
    margin-left: 130px;

    @include respond(sm) {
      margin-left: 16px;
    }

    @include respond(xsm) {
      margin-left: 0;
    }
  }

  &-address-listing {
    overflow-y: auto;
    height: 270px;
    margin: 24px 0 0 -26px;
    @include respond(xsm) {
      height: 200px;
    }
  }

  &-virus {
    height: 77px;
    width: 77px;
    position: absolute;
    left: 37px;
    top: 20px;
    @include respond(sm) {
      visibility: hidden;
    }
  }

  &-address-virus {
    height: 18px;
    position: absolute;
    top: 4px;
    left: -26px;

    @include respond(xsm) {
      display: none;
    }
  }

  &-location {
    padding-left: 26px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &-location strong {
    font-weight: 700;
  }

  &-location span {
    display: block;
  }
}

.react-tabs__tab-list {
  display: flex;
  border-bottom: 1px solid #d2dae1 !important;

  @include respond(sm) {
    border-bottom: 0 !important;
  }
}

.react-tabs__tab {
  position: relative;
  padding: 6px 12px;
  border-bottom: none;
  bottom: 0;
  color: rgba($color_primary_blue, 0.5) !important;
  cursor: pointer;
  @include respond(xsm) {
    padding: 6px 5px !important;
  }
  &:first-child {
    padding-left: 0;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    color: $color_primary_blue !important;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background: $color_primary_blue;
    visibility: hidden;
  }
}

.react-tabs__tab--selected {
  border: none !important;
  color: $color_primary_blue !important;

  &:before {
    box-shadow: none !important;
    visibility: visible;
    z-index: 99;
  }

  &:focus {
    outline: none;
  }
}

.single-tab-item {
  display: none;
}

//-----------------------------------------------
//  modal__activity
//-----------------------------------------------
.ReactModal__Overlay .ReactModal__Content.modal__activity {
  max-width: 600px;

  > div {
    max-height: 80vh;
  }

  .card__img {
    width: 85%;
    height: 200px;
    @include respond(sm) {
      width: 100%;
    }
  }

  .card__paragraph {
    padding-right: 24px;
  }

  .activity-modal {
    overflow-y: auto;
    max-height: 70vh;
  }
}

//-----------------------------------------------
//  modal__close
//-----------------------------------------------

.modal__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  background: #00529b;
  border: none;
  border-radius: 50%;
  transform: translate(50%, -50%);
  transition: 0.2 s;

  @include respond(sm) {
    transform: translate(-25%, -150%);
  }

  &:hover {
    background: #016bc2;
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 18px;
    width: 2px;
    top: 8px;
    background-color: #fff;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
