.note-text {
  background: transparent;
  border: none;
  font-size: 14px;
  padding-top: 12px;
  position: relative;
  line-height: 1.5;

  p {
    font-size: 14px;
  }

  .note-hover-content {
    opacity: 0;
    background: #fff;
    padding: 16px;
    pointer-events: none;
    position: absolute;
    transition: 0.3s;
    font-size: 14px;
    right: -5px;
    bottom: 5px;
    width: 500px;
    border-radius: 6px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    z-index: 9999;
    transform: translateY(-48px);

    @include respond(xsm) {
      width: 300px;
    }

    .hover-arrow {
      width: 15px;
      height: 15px;
      background: #fff;
      position: absolute;
      bottom: -5px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
      display: inline-block;
      transform: rotate(45deg);
      border-radius: 4px;
      z-index: 0;
      right: 20px;
    }
  }

  &:hover {
    .note-hover-content {
      opacity: 1;
      pointer-events: auto;
      transition: 0.3s;
      transform: translateY(-32px);
    }
  }
}
