.programme {
  padding: 25px 0 75px;

  @include respond(sm) {
    padding: 25px 0 55px;
  }

  .card__wrapper {
    margin: 0 -16px -32px;
    @include respond(lg) {
      margin: 0 -8px -16px;
    }
  }

  .btn-add-more {
    margin-left: 16px;
    margin-top: 16px;
    @include respond(lg) {
      margin-left: 8px;
      margin-top: 16px;
    }
  }

  .card {
    width: calc((100% - 96px) / 3);
    margin: 0 16px 32px;
    padding: 32px;
    padding-right: 36px;

    @include respond(lg) {
      width: calc((100% - 48px) / 3);
      margin: 0 8px 16px;
      padding: 16px;
    }

    @include respond(sm) {
      width: 100%;
      padding: 20px;
    }
  }

  .card__img {
    height: 123px;
    object-fit: cover;
    @include respond(sm) {
      height: 180px;
    }
  }
}

.activity-modal {
  padding-right: 24px;

  .card__img {
    height: 240px;
    object-fit: cover;
  }
}
