.donation {
   padding: 25px 0 75px;

  @include respond(sm) {
    padding: 25px 0 55px;
  }
  
  &__block:not(:last-child) {
    margin-bottom: 70px;
  }

  &__inner {
    @include respond(sm) {
      flex-direction: column;
      -ms-flex-direction: column;
    }
  }

  &__card {
    padding: 24px 16px;
  }

  &__card {
    margin-bottom: 12px;
    @include respond(sm) {
      margin-bottom: 16px;
    }
  }

  svg {
    margin-right: 8px;
  }

  &__text {
    margin-bottom: 24px;
    display: block;
  }

  &__text span {
    font-weight: 700;
  }

  strong {
    font-weight: 700;
    margin: 0 4px;
    &:first-child {
      margin-right: 0;
    }
  }

  &__cards-list {
    width: 100%;
  }

  &__svg {
    margin-right: 32px;

    @include respond(sm) {
      width: 140px;
      height: 140px;
      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}
