.section__nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: space-between;
  background: $color_primary_blue_darkest;
  box-shadow: 0px -4px 40px rgba(0, 82, 155, 0.6);
  z-index: 999999999;
  &-menu {
    display: flex;
  }

  &-menu--desktop {
    @include respond(lg) {
      display: none;
    }
  }

  &-item {
    position: relative;
  }

  &-item--show {
    background: $color_primary_dark;
  }

  &-item--show &-nested-menu {
    visibility: visible;
    opacity: 1;
  }

  &-link {
    display: flex;
    align-items: center;
    padding: 20px 24px;
    color: $color_primary_white;
    font-weight: 700;
    @include respond(xlg) {
      padding: 20px 16px;
    }
    @include respond(sm) {
      padding: 20px 16px;
    }

    @include respond(xsm) {
      padding: 20px 14px;
    }
  }

  // to not let the nav-link be clickble
  &-link-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    cursor: pointer;
  }

  &-link-test {
    background: $color_primary_blue_light;
    &:hover {
      background: #016bc2;
    }
  }

  &-link-donate {
    background: $color_secondary_green;
    &:hover {
      background: $color_secondary_green_dark;
    }
  }

  &-link .svg-icon {
    margin-left: 4px;
  }

  &-link,
  &-nested-link {
    transition: 0.2s;
  }

  &-menu--desktop &-link:hover {
    background: $color_primary_blue_dark;
  }

  &-menu-container:hover &-link {
    background: $color_primary_blue_dark;
  }

  &-item--show:hover &-link {
    background: $color_primary_dark;
  }

  &-menu--desktop .active--link:hover,
  &-menu--desktop .active--link,
  &-menu-container:hover .active--link {
    background: $color_primary_dark;
  }

  &-nested-menu {
    min-width: 284px;
    background: $color_primary_dark;
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 16px 28px 16px;
    visibility: hidden;
    opacity: 0;
  }

  &-nested-item {
    border-bottom: 1px solid rgba($color_primary_white, 0.1);
  }

  &-nested-link {
    color: $color_primary_white;
    font-size: 14px;
    display: block;
    padding: 16px 12px;
    &:hover {
      background: #3d5060;
    }
  }

  &-nested-link.active--link--nested {
    background: #3d5060;
  }
}

.mobile__nav {
  display: none;
  width: 100%;
  background: $color_primary_blue_darkest;

  @include respond(lg) {
    display: flex;
  }

  &-bottom-block{
    display: flex;
    width: 100%;
    position: relative;
    z-index: 99999999999;
  }

  .dropdown__trigger {
    display: flex;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    margin-right: auto;
    background: $color_primary_blue_darkest;

    @include respond(lg) {
      align-items: center;
      margin-left: 16px;
    }

    @include respond(sm) {
      height: 100%;
      justify-content: center;
      flex: 1;
      margin-left: 0;
    }
  }

  .burger__icon {
    display: flex;
    flex-direction: column;
    width: 19px;
    height: 19px;
    margin-right: 12px;
    transition: 0.3s;
    position: relative;
  }

  .burger__icon-line {
    height: 3px;
    width: 100%;
    background: $color_primary_white;
    border-radius: 2px;
    transition: 0.3s;
    opacity: 1;
    position: absolute;
    left: 0;

    &:nth-child(2) {
      top: 8px;
    }

    &:last-child {
      top: 16px;
    }
  }

  &--visible .burger__icon-line {
    &:first-child {
      transform: rotate(45deg);
      top: 8px;
    }
    &:nth-child(2) {
      visibility: hidden;
      opacity: 0;
    }
    &:last-child {
      top: 8px;
      transform: rotate(-45deg);
    }
  }

  &-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 140%;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
  }

  &--visible &-container {
    opacity: 1;
    visibility: visible;
  }

  &-list {
    padding: 24px;
    width: 100%;
    height: calc(100vh - 57px);
    overflow-y: auto;
    background: $color_nav_bg_mobile;
  }

  &-item:first-child > &-link {
    border-top: 0;
    padding-top: 0;
  }

  &-item:last-child &-link {
    border-bottom: 0;
  }

  &-nested-menu {
    margin-left: 24px;
  }

  &-link {
    display: block;
    color: $color_nav_link_mobile;
    font-weight: 600;
    font-size: 18px;
    border-top: 1px solid rgba(0, 82, 155, 0.039);
    border-bottom: 1px solid rgba(0, 82, 155, 0.039);
    padding: 16px 0;
  }

  .active--link {
    color: $color_active_link_mobile;
  }
}

.mobile-nav--visible {
  overflow: hidden;
}
