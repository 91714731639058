.podcast {
   padding: 25px 0 75px;

  @include respond(sm) {
    padding: 25px 0 55px;
  }

  .row {
    margin-bottom: -24px;
  }

  .card {
    margin-bottom: 24px;
    padding-right: 32px;
    @include respond(sm) {
      width: 100%;
    }
  }

  .heading__secondary {
    line-height: 1.5;
  }

  .podcast__wrapper {
    float: none;
    clear: both;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin-bottom: 16px;
  }
  .podcast__wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
