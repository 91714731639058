.help {
  padding: 25px 0 82px;

  @include respond(sm) {
    padding: 40px 0 60px;
  }

  &__card {
    padding: 32px 70px;
    width: 343px;
    position: relative;
    box-shadow: 0px 4px 40px rgba(0, 82, 155, 0.1);
    position: relative;

    &:before,
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    }

    &::before {
      background: #dbf4ff;
      width: calc(100% - 48px);
      left: 32px;
      top: 16px;
      z-index: -2;
    }

    &::after {
      background: #dbf4ff;
      opacity: 0.4;
      width: calc(100% - 84px);
      left: 104px;
      top: 32px;
      z-index: -1;
    }

    &-smaller-text {
      margin-bottom: 4px;
      display: block;
    }

    &-bigger-text {
      display: block;
      font-size: 28px;
      font-weight: 700;
      color: $color_primary_blue;
      line-height: 1;
      transition: 0.3s;
    }

    &-envelope {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -25%);

      @include respond(sm) {
        transform: translate(-30%, -30%);
        width: 100px;
        height: 100px;
      }
    }

    &-envelope svg {
      @include respond(sm) {
        width: 100%;
        height: 100%;
      }
    }

    &:hover &-bigger-text {
      color: $color_primary_blue_darkest;
    }
  }
}
