.card {
  padding: 24px;
  background: $card_bg;
  border-radius: $card-radius;
  box-shadow: 0px 4px 40px rgba(0, 82, 155, 0.1);

  &__img {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }

  &__heading.heading__primary {
    color: $color_primary_dark;
  }

  &__paragraph {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.card--table {
  padding: 0;
  width: 100%;
}

.card__title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
