//-----------------------------------------------
//  colors
//-----------------------------------------------

//---------------------
// primary colors
// ---------------------

$color_primary_dark: #283947;
$color_primary_dark_light: rgba(40, 57, 71, 0.7);
$color_primary_dark_lightest: rgba(40, 57, 71, 0.5);

$color_primary_blue: #2f80ed;
$color_primary_blue_dark: #016bc2;
$color_primary_blue_darkest: #00529b;

$color_primary_blue_light: #29abe2;

$color_primary_white: #fff;
$color_primary_white_light: rgba(255, 255, 255, 0.7);

//---------------------
// secondary colors
// ---------------------

$color_secondary_orange: #f97d21;

$color_secondary_pink: #f92672;
$color_secondary_pink_hover: #e60657;

$color_secondary_green: #1ea89e;
$color_secondary_green_dark: #00736a;

$color_tested: #016bc2;

//-----------------------------------------------
//  others
//-----------------------------------------------

$card-radius: 12px;
$card_bg: $color_primary_white;

//-----------------------------------------------
//  colors controlling -- mobile navigation
//-----------------------------------------------

$color_nav_bg_mobile: #f0f8ff;
$color_nav_link_mobile: $color_primary_blue_darkest;
$color_active_link_mobile: $color_primary_blue;
