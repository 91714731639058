 //-----------------------------------------------
//  buttons
//-----------------------------------------------

.bttn {
  display: inline-block;
  border-radius: 20px;
  background-color: $color_primary_blue;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 12px 20px;
  color: #fff;
  border: none;
  font-family: inherit;
  line-height: 1.4;
  transition: 0.3s;
  box-shadow: 0px 4px 40px rgba(47, 128, 237, 0.6);
  &:hover {
    background: $color_primary_blue_dark;
  }
}

.bttn__action {
  background-color: $color_secondary_pink;
  box-shadow: 0px 4px 40px rgba(249, 38, 114, 0.6);
  white-space: nowrap;
  &:hover {
    background-color: $color_secondary_pink_hover;
  }
}

.btn-add-more {
  color: $color_primary_blue;
  font-weight: 700;
  background: transparent;
  border: none;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
  transition: 0.3s;
  white-space: nowrap;
  &:hover {
    color: $color_primary_blue_dark;
  }
}

.link {
  color: $color_primary_blue;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  transition: 0.3s;

  &:hover {
    color: $color_primary_blue_dark;
  }
}

.btn-read-more {
  color: #2f80ed;
  font-weight: 700;
  background: transparent;
  border: none;
  font-size: 14px;
  font-family: 'Noto Sans', sans-serif;
  transition: 0.3s;
}
.btn-read-more:hover {
  color: #00529b;
}

 