.footer {
  .desc {
    text-align: center;
    width: 65%;
    margin: 48px auto 120px;
    color: #283947;
    line-height: 1.4;
    font-size: 16px;

    @include respond(sm) {
      width: 100%;
      margin: 0 auto 160px;
    }
  }
}
