//---------text color classes ---------//

.color-dark-light {
  color: $color_primary_dark_light;
}

.color-dark-lightest {
  color: $color_primary_dark_lightest;
}

.color-infected {
  color: $color_secondary_orange;
}

.color-tested {
  color: $color_tested;
}

.color-death {
  color: $color_secondary_pink;
}

.color-recovered {
  color: $color_secondary_green;
}

.text-small-bold {
  font-weight: 700;
  font-size: 0.75rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
}

.flex {
  display: flex;
}

.items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.items-center-vertical {
  display: flex;
  align-items: center;
}

.justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rounded {
  border-radius: 12px;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.row {
  margin: 0 -16px;
  flex-wrap: wrap;
}

.wrap {
  flex-wrap: wrap;
}

.w-full {
  width: 100%;
}

.svg-icon {
  fill: currentColor;
}
