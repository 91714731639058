.heading__primary {
  font-size: 1.31rem;
  font-weight: bold;
  line-height: 1.28;
  color: $color_primary_dark;
}

.heading__secondary {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #283947;
  opacity: 0.7;
}

.section--heading {
  margin-bottom: 24px;
}
