@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap&subset=devanagari');

html {
  font-size: 16px;
}

body {
  font-family: 'Noto Sans', sans-serif;
  background: rgba(240, 248, 255, 0.9);
  font-size: 16px;
  line-height: 1.4;
  color: $color_primary_dark;
}

*,
*:before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
