//-----------------------------------------------
// overflow issues mobile
//-----------------------------------------------

.main-app .global-bar .nav {
  @include respond(sm) {
    overflow: hidden;

    .content-right {
      display: none;
    }
  }
}

//-----------------------------------------------
//  header
//-----------------------------------------------

.header-bottom {
  color: #fff;
  padding: 22px 0;

  .heading__primary {
    color: $color_primary_white;
  }
}

//-----------------------------------------------
//  language switcher
//-----------------------------------------------

.lg-switcher {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.lg-switcher button {
  width: 50%;
  background: transparent;
  border: none;
  color: $color_primary_white;
  padding: 2px 8px;
  cursor: pointer;
}

.lg-toggle {
  background: rgba(255, 255, 255, 0.2);
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s;
}

.lg-toggle-eng {
  right: 50%;
}

//-----------------------------------------------
//  banner
//-----------------------------------------------

.banner {
  background: radial-gradient(
    72.1% 41.98% at 49.51% 59.05%,
    #016bc2 0%,
    #0264b6 100%
  );
  position: relative;
  height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  overflow: hidden;

  @include respond(lg) {
    padding-bottom: 24px;
  }

  @include respond(md) {
    height: auto;
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    @include respond(md) {
      display: block;
    }
  }

  &__content {
    width: 100%;
  }

  &__header {
    display: flex;
    padding-top: 16px;
    position: absolute;
    width: 100%;
    top: 0;
    @include respond(md) {
      position: static;
    }
  }

  &__header-title {
    font-size: 1.31rem;
    line-height: 1.68rem;
    font-weight: 700;
    color: $color_primary_white;
    @include respond(sm) {
      line-height: 1.2;
    }
  }

  &__header-title small {
    font-size: 14px;
    color: $color_primary_white_light;
    margin-left: 6px;
    font-weight: 400;
  }

  &__heading-wrap {
    width: 50%;
    text-align: center;
    margin: 0 auto 32px;
    position: relative;
    z-index: 56;

    @include respond(lg) {
      width: 100%;
      overflow: hidden;
      margin: 16px 0 60px;
    }
  }

  &__heading {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 700;
    color: $color_primary_white;

    @include respond(sm) {
      font-size: 24px;
    }

    @include respond(sm) {
      font-size: 18px;
      margin-top: 16px;
    }

    @include respond(xsm) {
      margin-top:8px;
    }
  }

  &__heading-small-text {
    color: $color_primary_white_light;
    margin-top: 16px;
    display: block;

    @include respond(sm) {
      margin: 20px 0 16px;
    }
  }

  &__stats-card-wrap {
    position: relative;
  }

  &__stats-card {
    padding: 32px 18px;
    margin: 0 12px;
    box-shadow: 0px 4px 40px rgba(0, 82, 155, 0.8);
    position: relative;
  }

  .col-1-4 {
    @include respond(md) {
      width: calc((100% - 48px) / 2);
      margin: 0 12px 24px;
    }

    @include respond(sm) {
      width: calc((100% - 36px) / 2);
      margin: 0 9px 18px;
      padding: 24px 16px 20px;
    }
  }

  &__stats-card > span:not(&__stats-country) {
    display: block;
    text-align: center;
  }

  &__stats-mun {
    font-size: 2.375rem;
    font-weight: 700;
    @include respond(sm) {
      line-height: 1;
    }
  }

  &__stats-country {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__stats-country span {
    margin-left: 2px;
    font-size: 0.875rem;
  }

  &__virus {
    position: absolute;
    left: 12px;
    top: -115px;
    width: 193px;
    height: 168px;
    @include respond(md) {
      width: 100px;
      height: 100px;
      top: -65px;
    }

    @include respond(sm) {
      width: 116px;
      height: 116px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &__virus svg {
    width: 100%;
    height: 100%;
  }

  &__virus-green {
    position: absolute;
    right: -23px;
    bottom: 1px;
    z-index: 2;
    transform: translate(15%, 56%);
    @include respond(sm) {
      bottom: 16px;
      right: -25px;
    }
  }

  &__video-btn {
    display: inline-flex;
    align-items: center;
    margin-top: 48px;
    transition: 0.3s;
    color: $color_primary_white_light;

    @include respond(sm) {
      margin-top: 24px;
    }

    &:hover {
      color: $color_primary_white;
    }
  }

  &__video-avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 3px solid $color_primary_white;
    overflow: hidden;
  }

  &__video-avatar img {
    width: 100%;
  }

  &__video-text {
    margin-left: 12px;
  }

  &__video-btn .svg-icon {
    margin-left: 8px;
  }
}

.video_player .contact-modal-content {
  padding: 32px !important;
  background: #fff;
  width: 100% !important;
  margin: 0 auto !important;

  > div {
    height: auto;
    margin: 0 auto 16px;
  }
}
