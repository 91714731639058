.call__action {
  padding: 60px 0;

  @include respond(sm) {
    padding: 32px 0;
  }

  &-desc {
    text-align: center;
    width: 48%;
    margin: 48px auto 0;

    @include respond(lg) {
      width: 65%;
    }

    @include respond(sm) {
      width: 100%;
    }
  }

  .link {
    font-weight: 400;
  }

  .bttn {
    margin-top: 16px;
  }
}
